
  import Vue from 'vue';
  import Component from 'vue-class-component';
  import { Action } from 'vuex-class';
  import { Prop } from 'vue-property-decorator';

  @Component
  export default class Loader extends Vue {
    @Prop({ default: false })
    showLoader!: boolean;

    @Action loader!: Function;
  }
